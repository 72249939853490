<template>
  <div class="nrhc_detail_container">
    <div class="header">
      <img :src="'data:image/png;base64,'+userBindInfoDetail.qrCode">
    </div>
    <div class="list">
      <van-cell-group>
        <!-- 卡号 -->
        <van-cell title="卡号"
                  :value="userBindInfoDetail.patCardNo"
                  v-if="userBindInfoDetail.patCardNo" />
        <!-- 姓名 -->
        <van-cell title="姓名"
                  :value="userBindInfoDetail.name"
                  v-if="userBindInfoDetail.name" />
        <!-- 性别 -->
        <van-cell title="性别"
                  :value="userBindInfoDetail.sex === 1?'男':'女'"
                  v-if="userBindInfoDetail.sex" />
        <!-- 民族 -->
        <van-cell title="民族"
                  :value="userBindInfoDetail.nation"
                  v-if="userBindInfoDetail.nation" />
        <!-- 出生日期 -->
        <van-cell title="出生日期"
                  :value="userBindInfoDetail.birthday"
                  v-if="userBindInfoDetail.birthday" />
        <!-- 年龄 -->
        <van-cell title="年龄"
                  :value="userBindInfoDetail.age"
                  v-if="userBindInfoDetail.age" />
        <!-- 联系电话 -->
        <van-cell title="联系电话"
                  :value="userBindInfoDetail.telephone"
                  v-if="userBindInfoDetail.telephone" />
        <!-- 证件类型 -->
        <van-cell title="证件类型"
                  :value="userBindInfoDetail.cardType"
                  v-if="userBindInfoDetail.cardType" />
        <!-- 证件号 -->
        <van-cell title="证件号"
                  :value="userBindInfoDetail.cardNo_2"
                  v-if="userBindInfoDetail.cardNo_2" />
        <!-- 是否成年 -->
        <van-cell title="是否成年"
                  :value="userBindInfoDetail.isAdult === 1 ? '是':'否'"
                  v-if="userBindInfoDetail.isAdult" />
        <van-cell title="监护人姓名"
                  :value="userBindInfoDetail.guardName"
                  v-if="userBindInfoDetail.guardName" />
        <!-- 监护人证件类型 -->
        <van-cell title="监护人证件类型"
                  :value="userBindInfoDetail.guardCardType"
                  v-if="userBindInfoDetail.guardCardType" />
        <!-- 监护人证件号 -->
        <van-cell title="监护人证件号"
                  :value="userBindInfoDetail.guardCardNo_2"
                  v-if="userBindInfoDetail.guardCardNo" />
        <!-- 地址 -->
        <van-cell title="地址"
                  :value="userBindInfoDetail.address"
                  v-if="userBindInfoDetail.address" />
      </van-cell-group>
    </div>
    <div class="btn">
      <van-button type="primary"
                  block
                  @click="back">返&nbsp;回&nbsp;上&nbsp;一&nbsp;页</van-button>
      <van-button type="info"
                  block
                  style="margin-top: 10px;"
                  @click="unbindUserClick">解&nbsp;绑&nbsp;信&nbsp;息</van-button>
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data () {
    return {
      patCardNo: "",
      userBindInfoDetail: {}
    };
  },
  created () {
    this.patCardNo = this.$route.query.patCardNo;
    this.getUserBindInfoDetail();
  },
  methods: {
    //获取健康卡列表
    async getUserBindInfoDetail () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        patCardNo: this.patCardNo
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getUserBindInfos",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.userBindInfoDetail = res.data[0];
      } else {
        this.$toast.fail(res.msg);
      }
    },
    async unbindUser () {
      this.$toast.loading({
        duration: 0,
        message: "解绑中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        patCardNo: this.patCardNo,
        recordId:this.userBindInfoDetail.id
      });
      const { data: res } = await formPost(
        "/wxapp/sysUserApp/sysUserApp/unbindUser",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    unbindUserClick () {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否解绑？"
        })
        .then(() => {
          this.unbindUser();
        })
        .catch(() => { });
    },
    back () {
      this.$router.go(-1);
    }
  }
};
</script>
<style scope>
.nrhc_detail_container .header {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.nrhc_detail_container .header img {
  width: 300px;
  height: 300px;
}
.list {
  margin-bottom: 280px;
}
.list .van-cell__title,
.list .van-cell__value {
  font-size: 38px !important;
}
.list .van-cell__value {
  flex: 2 !important;
}
.nrhc_detail_container .btn {
  width: 100%;
  height: 200px;
  position: fixed;
  left: 0;
  bottom: 60px;
  z-index: 1;
  background-color: white;
}
.nrhc_detail_container .btn .van-button {
  border-radius: unset !important;
  border: unset !important;
  font-size: 38px !important;
}
</style>